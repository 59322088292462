<template>
	<Dialog :dialog="UpdateInvoiceDialog" :dialog-width="900" @close="$emit('close')">
		<template v-slot:title>Update Invoice</template>
		<template v-slot:body>
			<v-form
				ref="extendCheckoutForm"
				v-model.trim="formValid"
				lazy-validation
				v-on:submit.stop.prevent="onSubmit"
			>
				<v-row class="mb-3">
					<v-col md="3" class="py-0">
						<label for="" class="required">Quick Book Invoice #</label>
					</v-col>
					<v-col md="9" class="py-0">
						<v-text-field
							hide-details
							outlined
							placeholder="Invoice #"
							v-model="invoiceManage.barcode"
							:rules="[vrules.required(invoiceManage.barcode, 'Quick Book Invoice #')]"
							@keypress="limitInput"
							:class="{
								required: !invoiceManage.barcode,
							}"
						>
						</v-text-field>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="3" class="py-0">
						<label for="" class="required">Invoice Date</label>
					</v-col>
					<v-col md="9" class="py-0">
						<DatePicker
							hide-details
							outlined
							custom-class="mt-0"
							v-model="invoiceManage.actual_date"
							:rules="[vrules.required(invoiceManage.actual_date, 'Invoice Date')]"
							required
							:minDate="today"
						>
						</DatePicker>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="3" class="py-0">
						<label for="">Payable Amount</label>
					</v-col>
					<v-col md="9" class="py-0">
						<v-text-field
							hide-details
							outlined
							readonly
							class="currency-input"
							placeholder="Payable Amount"
							type="number"
							min="0"
							max="9999"
							prepend-inner-icon="mdi-currency-usd"
							v-model="invoiceManage.payable_amount"
						></v-text-field>
					</v-col>
				</v-row>
				<v-row class="mb-3">
					<v-col md="3" class="py-0">
						<label for="">Paid Amount</label>
					</v-col>
					<v-col md="9" class="py-0">
						<v-text-field
							hide-details
							outlined
							class="currency-input"
							placeholder="Paid Amount"
							type="number"
							min="0"
							max="9999"
							:readonly="amountFullyPaid"
							v-on:change="updateQty"
							prepend-inner-icon="mdi-currency-usd"
							v-model.number.trim="invoiceManage.paid_amount"
							v-on:keypress="limitDecimal($event, invoiceManage.paid_amount)"
							@paste="disablePaste"
						></v-text-field>
					</v-col>
				</v-row>

				<v-divider></v-divider>

				<v-row class="mb-3">
					<v-col md="3" class="py-0">
						<div>Attachments</div>
					</v-col>
					<v-col md="9" class="d-flex align-top py-0 justify-content-between">
						<div class="text-muted"><em>Add upto 5 Attachments</em></div>
						<v-tooltip top :z-index="9999999">
							<template #activator="{ on, attrs }">
								<v-btn
									color="blue darken-4 white--text"
									v-on="on"
									v-bind="attrs"
									:disabled="files.length > 4"
									tile
									depressed
									v-on:click="addMore()"
								>
									<v-icon>mdi-plus</v-icon>
								</v-btn>
							</template>
							Add upto 5 Attachments
						</v-tooltip>
					</v-col>
					<v-col md="3"></v-col>
					<v-col md="9" class="pt-0 mt-2">
						<template v-for="(file, index) in files">
							<v-row :key="'attachments_invoice_' + index">
								<v-col md="5" class="py-1 pr-0">
									<v-file-input
										:id="`document-file-${index}`"
										placeholder="Select File"
										outlined
										prepend-icon=""
										prepend-inner-icon="mdi-attachment"
										hide-details
										v-model="file.file"
										class="text-truncate"
										v-on:change="updateFile(index, $event)"
										v-on:click:clear="updateFile(index, $event)"
									></v-file-input>
								</v-col>
								<v-col md="5" class="py-1 pr-0">
									<TextInput
										:id="`document-name-${index}`"
										v-model="file.name"
										hide-details
										placeholder="File Name"
										hideTopMargin
										:suffix="file.suffix"
										maxLength="40"
									></TextInput>
								</v-col>
								<v-col md="2" class="py-1">
									<v-btn
										:disabled="files.length < 2"
										v-on:click="removeFile(index)"
										color="red lighten-1 white--text"
										icon
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</v-col>
							</v-row>
							<!-- <div class="d-flex mb-2" :key="index">
								<div class="w-50 pr-3" style="margin-top: -12px; overflow: hidden">
									<v-file-input
										:id="`document-file-${index}`"
										placeholder="Select File"
										outlined
										class="mt-3"
										prepend-icon=""
										prepend-inner-icon="mdi-attachment"
										hide-details
										v-model="file.file"
										v-on:change="updateFile(index, $event)"
										v-on:click:clear="updateFile(index, $event)"
									></v-file-input>
								</div>
								<div class="w-50 pr-3" style="margin-top: -12px">
									<TextInput
										:id="`document-name-${index}`"
										v-model="file.name"
										hide-details
										placeholder="File Name"
										:suffix="file.suffix"
									></TextInput>
								</div>
								<div class="w-5 pr-3" style="margin-top: -12px">
									<v-btn
										:disabled="files.length < 2"
										v-on:click="removeFile(index)"
										color="red lighten-1 white--text"
										class="mt-3"
										icon
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</div>
							</div> -->
						</template>
					</v-col>
				</v-row>
			</v-form>
		</template>
		<template #action>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="!formValid || pageLoading"
				depressed
				color="blue darken-4"
				tile
				@click="onSubmit"
			>
				Save
			</v-btn>
			<v-btn depressed tile :disabled="pageLoading" v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<style>
/* To Make currency symbol in the center in Amount field */
.mdi-currency-usd::before {
	margin-top: 8px !important;
}
</style>
<script>
import { mapGetters } from "vuex";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import DatePicker from "@/view/components/DatePicker";
import { POST } from "@/core/services/store/request.module";
import MomentJS from "moment-timezone";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import MainMixin from "@/core/mixins/main.mixin.js";
import { /*toSafeInteger,*/ toString } from "lodash";
/* import objectPath from "object-path"; */
export default {
	mixins: [MainMixin],
	name: "extend-checkout-template",
	title: "Extend Checkout Template",
	props: {
		UpdateInvoiceDialog: {
			type: Boolean,
			default: false,
		},
		updatedData: {
			type: Object,
			default() {
				return new Object();
			},
		},
		customerDataId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			invoiceManage: {
				id: 0,
				barcode: null,
				actual_date: null,
				payable_amount: 0,
				paid_amount: 0,
			},
			files: [
				{
					file: null,
					name: null,
				},
			],
			amountFullyPaid: false,
		};
	},
	watch: {
		updatedData: {
			deep: true,
			immediate: true,
			handler(param) {
				this.init(param);
			},
		},
	},
	components: {
		Dialog,
		DatePicker,
		TextInput,
	},
	methods: {
		limitDecimal($event, value) {
			let string = toString(value);

			let keyCode = $event.keyCode ? $event.keyCode : $event.which;

			// only allow number and one dot
			if (
				((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || string.indexOf(".") != -1)) ||
				string.length > 20
			) {
				// 46 is dot
				$event.preventDefault();
			}

			// restrict to 2 decimal places
			if (string != null && string.indexOf(".") > -1 && string.split(".")[1].length > 1) {
				$event.preventDefault();
			}
		},
		today() {
			return MomentJS(new Date()).format("YYYY-MM-DD");
		},
		limitInput(event) {
			/* console.log("Event => ",event.target.value.length); */
			let input = event.target.value;
			if (input && input.length > 13) {
				event.preventDefault();
			}
		},
		updateFile(index, file) {
			if (file && file.name) {
				this.files[index].name = file.name.split(".").slice(0, -1).join(".");
				this.files[index].suffix = `.${file.name.split(".").pop()}`;
			} else {
				this.files[index].name = null;
				this.files[index].suffix = null;
			}
		},
		addMore() {
			this.files.push({
				file: null,
				name: null,
			});
		},
		removeFile(index) {
			this.files.splice(index, 1);
			if (this.files.length < 1) {
				this.addMore();
			}
		},
		init(data) {
			/* this.alldata = data; */

			this.invoiceManage = {
				id: data.id,
				customer: this.customerDataId,
				actual_date: data.actual_date
					? MomentJS(data.actual_date, "YYYY-MM-DD HH:II:SS").format("YYYY-MM-DD")
					: null,
				barcode: data.barcode ? data.barcode : null,
				payable_amount: data.payable_amount
					? data.payable_amount.toFixed(2)
					: data
					? data.cost.toFixed(2)
					: 0,
				paid_amount: data.paid_amount ? data.paid_amount.toFixed(2) : 0,
			};

			if (this.invoiceManage.payable_amount == this.invoiceManage.paid_amount) {
				this.amountFullyPaid = true;
			} else {
				this.amountFullyPaid = false;
			}
		},
		updateQty() {
			if (this.invoiceManage.paid_amount > this.invoiceManage.payable_amount) {
				this.invoiceManage.paid_amount = this.invoiceManage.payable_amount;
			}
		},
		onSubmit() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.extendCheckoutForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.extendCheckoutForm.validate()) {
				return false;
			}

			this.pageLoading = true;
			// this.invoiceManage.payable_amount = toSafeInteger(this.invoiceManage.payable_amount);
			// this.invoiceManage.paid_amount = toSafeInteger(this.invoiceManage.paid_amount);
			let formData = new FormData();

			if (this.invoiceManage && this.invoiceManage.customer) {
				formData.append("customer", this.invoiceManage.customer ? this.invoiceManage.customer : 0);
			}
			if (this.invoiceManage && this.invoiceManage.barcode) {
				formData.append("barcode", this.invoiceManage.barcode ? this.invoiceManage.barcode : null);
			}
			if (this.invoiceManage && this.invoiceManage.actual_date) {
				formData.append(
					"actual_date",
					this.invoiceManage.actual_date ? this.invoiceManage.actual_date : null
				);
			}
			if (this.invoiceManage && this.invoiceManage.payable_amount) {
				formData.append(
					"payable_amount",
					this.invoiceManage.payable_amount ? this.invoiceManage.payable_amount : 0
				);
			}
			if (this.invoiceManage && this.invoiceManage.paid_amount) {
				formData.append(
					"paid_amount",
					this.invoiceManage.paid_amount ? this.invoiceManage.paid_amount : 0
				);
			}

			for (let i = 0; i < this.files.length; i++) {
				if (this.files && this.files[i] && this.files[i].file) {
					formData.append(`file[${i}][file]`, this.files[i].file);
				}
				if (this.files && this.files[i] && this.files[i].name) {
					formData.append(`file[${i}][name]`, this.files[i].name);
				}
			}

			let requestTYPE = POST;
			let requestURL = `invoice/${this.updatedData.id}`;

			this.$store
				.dispatch(requestTYPE, {
					url: requestURL,
					data: formData,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Invoice updated successfully." },
						this.$emit("success", true),
					]);

					this.$refs.extendCheckoutForm.reset();

					//this.UpdateInvoiceDialog = false;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
};
</script>
