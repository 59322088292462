<template>
	<!-- <v-sheet class="recurring-main-listing"> -->
	<div style="height: calc(100vh - 100px)">
		<v-layout class="">
			<v-flex class="py-0">
				<div style="min-width: 150px; max-width: 320px">
					<v-select
						v-model="status"
						class="listing-select"
						hide-details
						:disabled="pageLoading"
						:items="statusData"
						solo
						@change="statusFilterRows($event)"
						:menu-props="{ offsetY: true, contentClass: 'rounded-lg border' }"
					>
						<template #item="{ item }">
							<div class="d-flex align-center py-1">
								<div class="mr-2" style="min-width: 60px">
									<v-chip class="px-2" dark :color="item.status_color">{{ item.status_count }}</v-chip>
								</div>
								<div class="fw-500">{{ item.text }}</div>
							</div>
						</template>
					</v-select>
				</div>
			</v-flex>
			<!-- <v-flex class="py-0">
				<div class="my-2 ml-4 d-flex" style="height: 35px !important">
					<div class="mr-4 py-2 px-3 rounded blue lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="getListing()">All </span>
					</div>
					<div class="mr-4 py-2 px-3 rounded blue lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Raised')">Raised : </span>
						<v-chip class="px-2 py-1" label color="blue white--text" small>
							{{ count.raisedRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded green lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Paid')">Paid : </span>
						<v-chip class="px-2 py-1" label color="green white--text" small>
							{{ count.paidRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded warning lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Unpaid')">Unpaid : </span>
						<v-chip class="px-2 py-1" label color="warning white--text" small>
							{{ count.unpaidRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded cyan lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Postponed')"
							>Postponed :
						</span>
						<v-chip class="px-2 py-1" label color="cyan white--text" small>
							{{ count.postponedRecurring }}
						</v-chip>
					</div>
					<div class="mr-4 py-2 px-3 rounded red lighten-4">
						<span class="fw-600 mr-2 cursor-pointer" v-on:click="statusFilter('Cancelled')"
							>Cancelled :
						</span>
						<v-chip class="px-2 py-1" label color="red white--text" small>
							{{ count.cancelledRecurring }}
						</v-chip>
					</div>
				</div>
			</v-flex> -->
			<v-spacer></v-spacer>
			<v-flex class="py-0 mt-2 d-flex text-right listing-right justify-content-end">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<template v-if="false">
						<v-flex class="d-flex justify-content-end mr-1">
							<div class="mr-2 fw-600 my-auto">Filter By</div>
							<v-autocomplete
								style="max-width: 250px !important"
								:items="customerData"
								item-text="company_name"
								item-value="id"
								v-model="customer_filter"
								:menu-props="{ bottom: true, offsetY: true }"
								outlined
								clearable
								hide-details
								class="filterSelect"
								placeholder="Customer"
								content-class="filterSelectContent"
							>
							</v-autocomplete>
						</v-flex>
						<v-autocomplete
							:items="users"
							style="max-width: 250px !important"
							v-model="user_filter"
							:menu-props="{ bottom: true, offsetY: true }"
							outlined
							hide-details
							clearable
							item-text="display_name"
							item-value="id"
							class="mr-2"
							placeholder="Sales Person"
						>
							<template v-slot:selection="data">
								<v-chip small v-bind="data.attrs" :input-value="data.selected">
									<v-avatar left v-if="data.item.profile_img">
										<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
										<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
									</v-avatar>

									{{ data.item.display_name }}
								</v-chip>
							</template>
							<template v-slot:item="data">
								<template>
									<v-list-item-avatar>
										<img v-if="data.item.profile_img" :src="data.item.profile_img" />
										<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
									</v-list-item-avatar>
									<v-list-item-content>
										<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
									</v-list-item-content>
								</template>
							</template>
						</v-autocomplete>
						<v-autocomplete
							v-model="service_type"
							:items="serviceTypes"
							item-text="text"
							item-value="value"
							:menu-props="{ bottom: true, offsetY: true }"
							outlined
							placeholder="Service Type"
							clearable
							hide-details
							class="filterSelect mr-2"
							content-class="filterSelectContent"
						>
							<template v-slot:item="data">
								<template>
									<v-list-item-icon class="mr-0 my-1">
										<v-icon class="mdi-18px" :color="data.item.color">mdi-circle</v-icon>
									</v-list-item-icon>
									<v-list-item-content>
										<v-list-item-title v-html="data.item.text"></v-list-item-title>
									</v-list-item-content>
								</template>
							</template>
						</v-autocomplete>
						<v-autocomplete
							style="max-width: 50% !important"
							:items="DateFilters"
							item-text="text"
							item-value="value"
							:menu-props="{ bottom: true, offsetY: true }"
							outlined
							hide-details
							clearable
							v-model="date_type_filter"
							class="filterSelect mr-2"
							placeholder="Select Date Type"
							content-class="filterSelectContent"
						>
						</v-autocomplete>
						<DateRangePicker
							hide-details
							hide-top-margin
							:clear="date_status"
							clearable
							v-model="filter_date"
						>
						</DateRangePicker>
					</template>
					<v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="importDialog = true"
					>
						<v-icon>mdi-database-import</v-icon>
					</v-btn>
					<v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="exportDialog = true"
					>
						<v-icon>mdi-database-export</v-icon>
					</v-btn>
					<!-- <v-btn
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="summary_show = !summary_show"
						:disabled="pageLoading"
					>
						<v-icon>mdi-chart-box-outline</v-icon>
					</v-btn> -->
					<v-menu
						v-if="internaldata == false"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('invoice')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('invoice')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</template>
			</v-flex>
		</v-layout>
		<v-layout class="mb-2"><div class="mx-2 fw-600 my-auto">Filter By</div></v-layout>
		<v-layout class="">
			<v-flex class="ml-2 d-flex justify-content-start pr-1">
				<v-select
					style="min-width: 12.7rem !important; max-width: 12.7rem !important"
					placeholder="Select Entity"
					outlined
					hide-details
					:items="entity"
					class="mr-2 filterSelect"
					content-class="filterSelectContent"
					v-model="entity_type"
					:menu-props="{ offsetY: true, bottom: true, contentClass: 'rounded-lg border' }"
					clearable
					@change="actualDateFilter()"
				>
					<template #item="{ item }">
						<v-avatar size="35">
							<ImageTemplate
								style="max-width: 30px; height: 30px; box-shadow: 0px 0px 2px 0px #888"
								circle
								:src="`media/logos/${item.img.replace('_', '-')}.png`"
								class="mr-2 mt-1"
							></ImageTemplate>
						</v-avatar>
						<span class="fw-600">{{ item.text }}</span>
					</template>
				</v-select>
				<!-- <v-autocomplete
					v-model="entity_type"
					:items="entity"
					item-text="text"
					item-value="value"
					:menu-props="{ bottom: true, offsetY: true }"
					outlined
					v-on:click:clear="defaultFilter = {}"
					placeholder="Entity"
					clearable
					hide-details
					class="filterSelect mr-2"
					content-class="filterSelectContent"
				>
					<template v-slot:item="data">
						<template>
							<v-list-item-icon class="mr-0 my-1">
								<v-icon class="mdi-18px" :color="data.item.color">mdi-circle</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title v-html="data.item.text"></v-list-item-title>
							</v-list-item-content>
						</template>
						<v-list-item-action
							class="align-self-center common-listing-row:nth-child"
							>
							<v-list-item-subtitle
								class="text-lowercase font-weight-500 font-size-14"
							>
							</v-list-item-subtitle
							>
							</v-list-item-action>
					</template>
				</v-autocomplete> -->
				<v-autocomplete
					style="/* max-width: 25rem !important */"
					:items="customerData"
					v-on:click:clear="defaultFilter = {}"
					item-text="company_name"
					item-value="id"
					v-model="customer_filter"
					:menu-props="{ bottom: true, offsetY: true }"
					outlined
					clearable
					hide-details
					class="mr-2"
					placeholder="Customer"
					@change="actualDateFilter()"
					content-class="filterSelectContent"
				>
				</v-autocomplete>

				<v-autocomplete
					:items="users"
					style="min-width: 20rem; max-width: 20rem !important"
					v-model="user_filter"
					:menu-props="{ bottom: true, offsetY: true }"
					@click:clear="defaultFilter = {}"
					outlined
					hide-details
					clearable
					item-text="display_name"
					item-value="id"
					class="mr-2 filterSelect"
					placeholder="Sales Person"
					@change="actualDateFilter()"
				>
					<template #selection="data">
						<v-chip
							class="px-1"
							color="blue lighten-5"
							x-small
							v-bind="data.attrs"
							:input-value="data.selected"
						>
							<v-avatar small left v-if="data.item.profile_img">
								<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
								<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
							</v-avatar>
							<span class="fw-600 text-uppercase">{{ data.item.display_name }}</span>
						</v-chip>
					</template>
					<template #item="data">
						<template>
							<v-list-item-avatar>
								<img v-if="data.item.profile_img" :src="data.item.profile_img" />
								<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
							</v-list-item-avatar>
							<v-list-item-content>
								<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
							</v-list-item-content>
						</template>
					</template>
				</v-autocomplete>
				<v-autocomplete
					v-model="service_type"
					:items="serviceTypes"
					item-text="text"
					item-value="value"
					:menu-props="{
						bottom: true,
						offsetY: true,
						contentClass: 'rounded-lg border',
						maxWidth: '19rem',
					}"
					outlined
					@click:clear="defaultFilter = {}"
					placeholder="Service Type"
					clearable
					hide-details
					class="filterSelect mr-2"
					content-class="filterSelectContent"
					style="min-width: 4rem !important; max-width: 19rem !important"
					@change="actualDateFilter()"
				>
					<template #item="{ item }">
						<template>
							<v-list-item-icon class="mr-0 my-1">
								<v-icon class="mdi-18px" :color="item.color">mdi-circle</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>{{ item.text }}</v-list-item-title>
							</v-list-item-content>
						</template>
						<!-- <v-list-item-action class="align-self-center common-listing-row:nth-child">
							<v-list-item-subtitle class="text-lowercase font-weight-500 font-size-14">
							</v-list-item-subtitle>
						</v-list-item-action> -->
					</template>
					<template #prepend-inner>
						<v-icon size="18" :color="serviceTypes.find((v) => v.value == service_type)?.color">
							mdi-circle
						</v-icon>
					</template>
					<template #selection="{ item }">
						<div class="fw-600 text-uppercase">{{ item.value }}</div>
					</template>
				</v-autocomplete>
				<!-- 	<div class="mr-2 fw-600 my-auto">Filter By Date</div> -->
				<v-autocomplete
					style="min-width: 7rem !important; max-width: 17rem !important"
					:items="DateFilters"
					item-text="text"
					item-value="value"
					:menu-props="{
						bottom: true,
						offsetY: true,
						contentClass: 'rounded-lg border',
						maxWidth: '17rem',
					}"
					outlined
					hide-details
					clearable
					v-model="date_type_filter"
					class="filterSelect mr-2"
					placeholder="Select Date Type"
					content-class="filterSelectContent"
					@click:clear="date_type_filter = null"
					@change="changeDate"
				>
					<template #selection="{ item }">
						<div class="fw-600 text-uppercase">{{ item.text }}</div>
					</template>
				</v-autocomplete>
				<!-- v-on:click:clear="defaultFilter = {}" -->
				<DateRangePicker
					:id="dp_id"
					hide-details
					:inlineStyle="{ minWidth: '18rem !important', maxWidth: '19rem !important' }"
					hide-top-margin
					clearable
					v-model="filter_date"
					@click:clear="filter_date = null"
					@change="changeDate"
				></DateRangePicker>
				<!-- <v-btn
					color="blue darken-4 text-white"
					class="mx-2"
					depressed
					tile
					:disabled="pageLoading"
					v-on:click="actualDateFilter()"
					x-small
					min-height="30px"
				>
					<v-icon center> mdi-magnify </v-icon>
				</v-btn> -->
				<div class="ml-2">
					<v-btn
						depressed
						color="white"
						class="red darken-4"
						tile
						text
						@click="clearFliter"
						x-small
						min-height="30px"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</div>
			</v-flex>
		</v-layout>

		<v-layout
			class="page-summary"
			style="display: grid; grid-template-columns: repeat(8, 1fr)"
			:class="summary_show ? 'show mt-2' : ''"
		>
			<template v-for="(statusdata, key) in statusData">
				<PageSummeryBox
					:key="key"
					:color="statusdata.status_color"
					:text="statusdata.text"
					:count="statusdata.status_count"
					@click="status != statusdata.value && statusFilterRows(statusdata.value)"
				/>
			</template>
			<!-- <v-flex
				class="summary-details bg-light-blue"
				style="padding: 8px 5px 0px 0px"
				v-on:click="statusFilterRows('all')"
			>
				<div class="name blue--text text-capitalize summary-text">All Invoice</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="blue">mdi-file-chart-outline</v-icon>
					</div>
					<div class="number blue--text ms-2 mt-2" v-if="count && count.allInvoice">
						{{ count.allInvoice }}
					</div>
				</div>
			</v-flex>
			<v-flex
				class="summary-details bg-light-d-cyan"
				style="padding: 8px 5px 0px 0px"
				v-on:click="statusFilterRows('6')"
			>
				<div class="name cyan--text text-capitalize summary-text">Draft</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="cyan">mdi-file-chart-outline</v-icon>
					</div>
					<div class="number cyan--text ms-2 mt-2" v-if="count && count.draftInvoice">
						{{ count.draftInvoice }}
					</div>
				</div>
			</v-flex>
			<v-flex
				class="summary-details bg-light-blue"
				style="padding: 8px 5px 0px 0px"
				v-on:click="statusFilterRows('1')"
			>
				<div class="name blue--text text-capitalize summary-text">Raised</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="blue">mdi-file-chart-outline</v-icon>
					</div>
					<div class="number blue--text ms-2 mt-2" v-if="count && count.raisedInvoice">
						{{ count.raisedInvoice }}
					</div>
				</div>
			</v-flex>
			<v-flex
				class="summary-details bg-light-green"
				style="padding: 8px 5px 0px 0px"
				v-on:click="statusFilterRows('2')"
			>
				<div class="name green--text text-capitalize summary-text">Paid</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="green">mdi-file-chart-outline</v-icon>
					</div>
					<div class="number green--text ms-2 mt-2" v-if="count && count.paidInvoice">
						{{ count.paidInvoice }}
					</div>
				</div>
			</v-flex>
			<v-flex
				class="summary-details bg-light-d-green"
				style="padding: 8px 5px 0px 0px"
				v-on:click="statusFilterRows('8')"
			>
				<div class="name d-green--text text-capitalize summary-text">Issued</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="#008080">mdi-file-chart-outline</v-icon>
					</div>
					<div class="number d-green--text ms-2 mt-2" v-if="count && count.issuedInvoice">
						{{ count.issuedInvoice }}
					</div>
				</div>
			</v-flex>
			<v-flex
				class="summary-details bg-light-orange"
				style="padding: 8px 5px 0px 0px"
				v-on:click="statusFilterRows('3')"
			>
				<div class="name orange--text text-capitalize summary-text">Partially Paid</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="orange">mdi-file-chart-outline</v-icon>
					</div>
					<div class="number orange--text ms-2 mt-2" v-if="count && count.prtiallyInvoice">
						{{ count.prtiallyInvoice }}
					</div>
				</div>
			</v-flex>
			<v-flex
				class="summary-details bg-light-red"
				style="padding: 8px 5px 0px 0px"
				v-on:click="statusFilterRows('4')"
			>
				<div class="name red--text text-capitalize summary-text">Pending Issuance</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="red">mdi-file-chart-outline</v-icon>
					</div>
					<div class="number red--text ms-2 mt-2" v-if="count && count.pendingInvoice">
						{{ count.pendingInvoice }}
					</div>
				</div>
			</v-flex>
			<v-flex
				class="summary-details bg-light-d-blue"
				style="padding: 8px 5px 0px 0px"
				v-on:click="statusFilterRows('7')"
			>
				<div class="name d-blue--text text-capitalize summary-text">Overdue Issuance</div>
				<div class="d-flex align-itms-center">
					<div>
						<v-icon style="margin-left: -10px" color="#191970">mdi-file-chart-outline</v-icon>
					</div>
					<div class="number d-blue--text ms-2 mt-2" v-if="count && count.overDueInvoice">
						{{ count.overDueInvoice }}
					</div>
				</div>
			</v-flex> -->
		</v-layout>

		<v-layout class="page-summary show" v-if="false">
			<v-flex
				:class="`summary-details ${item.color} lighten-5`"
				v-for="(item, index) in status_list"
				:key="index"
			>
				<v-icon size="30" :color="item.color">mdi-file-chart-outline</v-icon>
				<div :class="`name ${item.color}--text no-wrap`">{{ item.name }}</div>
				<div :class="`number ${item.color}--text`">{{ item.total_count }}</div>
			</v-flex>
		</v-layout>
		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Customer #, Invoice #, Project #, Company Name"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Dialog :dialog="export_dialog">
			<template v-slot:title> Import Recurrings </template>
			<template v-slot:body> Export </template>
			<template v-slot:action>
				<v-btn tile depressed class="mr-3" @click="export_dialog = false">Close</v-btn>
				<v-btn tile depressed color="blue darken-4 white--text" class="">Import</v-btn>
			</template>
		</Dialog>

		<template>
			<Table
				type="invoice"
				delete-endpoint="invoice/"
				detail-route="invoice-detail"
				v-on:reload:content="filterRows"
				v-on:update:dialog="getInvoicedata"
				:page-loading="pageLoading"
				v-on:open-dialog="getInvoicedata($event)"
				:dialog-update="true"
				delete-note="All transactions of this member will also be deleted."
			></Table>
		</template>
		<UpdateInvoice
			:customer-data-id="customerId"
			v-if="UpdateInvoiceDialog"
			v-on:success="(UpdateInvoiceDialog = false), getListing()"
			:updated-data="singleData"
			v-on:close="UpdateInvoiceDialog = false"
			:update-invoice-dialog="UpdateInvoiceDialog"
		>
		</UpdateInvoice>
		<ExportDialog
			endpoint="invoice-export"
			title="Invoice"
			:export-dialog.sync="exportDialog"
			:cols="draggableThead"
			v-on:close="exportDialog = false"
		></ExportDialog>
		<ImportDialog
			endpoint="invoice-project/import"
			title="Invoice"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
			v-on:refress="getListing()"
		></ImportDialog>
		<CreateInvoice
			v-if="CreateInvoiceDialog"
			v-on:success="CreateInvoiceDialog = false"
			v-on:refressdata="getListing()"
			v-on:close="CreateInvoiceDialog = false"
			:create-invoice-dialog="CreateInvoiceDialog"
			:related-id="customerId"
			internal
		>
		</CreateInvoice>
	</div>
	<!-- </v-sheet> -->
</template>
<script>
import Dialog from "@/view/components/Dialog";
import ListingMixin from "@/core/mixins/listing.mixin";
import HeightMixin from "@/core/mixins/height.mixin";
import { GET, QUERY } from "@/core/services/store/request.module";
import UpdateInvoice from "@/view/module/components/update-invoice";
import CreateInvoice from "@/view/module/components/Create-invoice";
import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_THEAD,
	SET_FILTER,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_BULK_ACTION,
	SET_ACTION,
} from "@/core/services/store/listing.module";
import DateRangePicker from "@/view/components/DateRangePickerV2";
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import ImageTemplate from "@/view/components/Image";
import PageSummeryBox from "@/view/components/PageSummeryBox";

export default {
	name: "Invoice-listing",
	title: "Listing Invoice",
	mixins: [ListingMixin, HeightMixin],
	data() {
		return {
			skipBottomHeight: 65,
			export_dialog: false,
			UpdateInvoiceDialog: false,
			CreateInvoiceDialog: false,
			singleData: {},
			date_type_filter: null,
			importDialog: false,
			status_filter: null,
			filter_date: [],
			user_filter: 0,
			customer_filter: 0,
			users: [],
			summary_show: true,
			customerData: [],
			/* DateFilters: [
				{ value: 6, text: "Draft" },
				{ value: 1, text: "Raised" },
				{ value: "due_date", text: "Due Date" },
			], */
			DateFilters: [
				{ value: "invoice_date", text: "Invoice Date" },
				{ value: "add_date", text: "Add Date" },
				{ value: "due_date", text: "Due Date" },
			],
			entity: [
				{ value: "genic-solution", text: "Genic Solution", color: "blue", img: "genic_solution" },
				{ value: "bthrust", text: "Bthrust", color: "red", img: "bthrust" },
			],
			serviceTypes: [
				{ value: "seo", text: "SEO", color: "blue" },
				{ value: "ssl", text: "SSL", color: "green" },
				{ value: "web", text: "Web", color: "orange" },
				{ value: "hosting", text: "Hosting", color: "blue-grey" },
				{ value: "domain", text: "Domain", color: "red" },
				{ value: "maintenance", text: "Maintenance", color: "cyan" },
				{ value: "software", text: "Software", color: "#191970" },
			],
			service_type: null,
			entity_type: null,
			search_query: null,
			date_status: Number(new Date()),
			actula_date_filter: null,
			drawerFilter: false,
			pageTitle: "Invoice",
			pageBreadcrumbs: [{ text: "Invoice", disabled: true }],
			endpoint: "invoice",
			defaultFilter: {},
			count: {},
			status: "all",
			statusData: [],
			pageLoading: false,
			customerId: 0,
			dp_id: +new Date(),
		};
	},
	props: {
		relatedId: {
			type: Number,
			default: 0,
		},
		internalId: {
			type: Number,
			default: 0,
		},
		internaldata: {
			type: Boolean,
			default: false,
		},
		updatedData: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		relatedId() {
			this.getListing();
		},
		updatedData() {
			this.getListing();
		},
	},
	methods: {
		getsettingData() {
			this.pageLoading = true;
			this.$store
				.dispatch(GET, {
					url: `invoice-setting`,
				})
				.then((data) => {
					this.customerData = data.customer;
					if (Array.isArray(data.users)) {
						this.users = data.users.filter((user) => user.users_type == "sales");
					} else {
						this.users = [];
					}
					this.statusData = data.status;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		clearFliter() {
			this.date_status = Number(new Date());
			(this.filter_date = null),
				(this.customer_filter = null),
				(this.service_type = null),
				(this.user_filter = null),
				(this.entity_type = null),
				(this.status = "all"),
				(this.date_type_filter = null),
				(this.defaultFilter = {});
			this.$router.replace({
				name: "invoice",
				query: { t: new Date().getTime() },
			});
			this.$nextTick(() => {
				this.getListing();
			});
			this.dp_id = +new Date();
			/* this.getListing(); */
		},
		statusFilterRows(event) {
			if (event) {
				this.status = event;
			}

			let filterArray = {
				filter_date: this.filter_date,
				date_type_filter: this.date_type_filter,
				status: this.status,
				internal_id: this.internalId,
				service_type: this.service_type,
				entity: this.entity_type,
				customer: this.customer_filter,
				user_filter: this.user_filter,
				t: new Date().getTime(),
				search: this.listingSearch || undefined,
			};
			this.$router.push({
				query: filterArray,
			});
			this.$store
				.dispatch(QUERY, {
					url: `invoice`,
					data: filterArray,
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
					this.addvanceFilterAarry = [];
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		create_invoice() {
			this.CreateInvoiceDialog = true;
		},

		actualDateFilter() {
			let filterArray = {
				filter_date: this.filter_date,
				date_type_filter: this.date_type_filter,
				status: this.status,
				internal_id: this.internalId,
				service_type: this.service_type,
				entity: this.entity_type,
				customer: this.customer_filter,
				user_filter: this.user_filter,
				t: new Date().getTime(),
				search: this.listingSearch || undefined,
			};
			this.pageLoading = true;
			this.allFiltersSetting();
			this.$store
				.dispatch(QUERY, {
					url: `invoice`,
					data: filterArray,
				})
				.then((data) => {
					this.$router.push({
						query: filterArray,
					});
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersSetting() {
			this.$router.push({
				name: "invoice",
				query: {
					filter_date: this.filter_date,
					date_type_filter: this.date_type_filter,
					status: this.status,
					internal_id: this.internalId,
					service_type: this.service_type,
					entity: this.entity_type,
					customer: this.customer_filter,
					user_filter: this.user_filter,
					t: new Date().getTime(),
					search: this.listingSearch || undefined,
				},
			});
		},
		getInvoicedata(id) {
			this.UpdateInvoiceDialog = true;
			this.$store
				.dispatch(GET, {
					url: `invoice/${id}`,
				})
				.then((data) => {
					this.singleData = data.invoice;
					this.customerId = data.invoice.customer;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},

		getInvoiceCount() {
			this.$store
				.dispatch(QUERY, {
					url: `invoice-count`,
					data: {
						related_id: this.relatedId ? this.relatedId : 0,
					},
				})
				.then((data) => {
					this.count = data;
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		changeDate() {
			if (this.date_type_filter && this.filter_date && this.filter_date.length > 1) {
				this.actualDateFilter();
			} else if (
				this.date_type_filter == null &&
				(this.filter_date == null || this.filter_date.length == 0)
			) {
				this.actualDateFilter();
			}
		},
	},
	computed: {
		...mapGetters(["currentUser"]),
	},

	components: {
		//RecurringKanban,
		Dialog,
		UpdateInvoice,
		/* DatePicker, */
		CreateInvoice,
		DateRangePicker,
		ImageTemplate,
		PageSummeryBox,
	},
	beforeMount() {
		this.user_filter = toSafeInteger(this.$route.query.user_filter) || null;
		this.customer_filter = toSafeInteger(this.$route.query.customer) || null;
		this.service_type = this.$route.query.service_type || null;
		this.entity_type = this.$route.query.entity || null;
		this.status = this.$route.query.status || "all";
		this.date_type_filter = this.$route.query.date_type_filter || [];
		this.filter_date = this.$route.query.filter_date || [];

		if ((this.currentUser.role == 1 || this.currentUser.role == 4) && !this.user_filter) {
			this.user_filter = toSafeInteger(this.$route.query.user_filter);

			this.$router.push({
				name: "invoice",
				query: {
					...this.$route.query,
					user_filter: this.user_filter,
					t: new Date().getTime(),
				},
			});
		}

		/* this.defaultFilter = {
			filter_date: this.filter_date,
			date_type_filter: this.date_type_filter,
			internal_id: this.internalId,
			service_type: this.service_type,
			customer: this.customer_filter,
			entity: this.entity_type,
			user: this.user_filter,
		}; */
	},
	mounted() {
		this.getInvoiceCount();
		this.getsettingData();
		let filterQuery = this.$route.query;

		this.$router.push({
			name: "invoice",
			query: {
				...filterQuery,
				t: new Date().getTime(),
			},
		});
	},
};
</script>
